import { useState } from "react";
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Container, InputGroup, InputGroupText, Input, Tooltip } from 'reactstrap';
import numeral from "numeral";
import moment from 'moment';
import { formatUnits } from '@ethersproject/units'
//@ts-ignore
import { NotificationManager } from 'react-notifications';
import { useNftData } from '../hooks';
import { shortenAddress } from 'utils/index'
import { ReactComponent as EmptyImg } from 'assets/img/emptyImg.svg'
import { ReactComponent as RefreshIcon } from 'assets/img/refresh.svg'
import "./index.less"

const SearchDetailPage = (props: RouteComponentProps<{ NFTId: string }>) => {
  const {
    match: {
      params: { NFTId }
    }
  } = props
  const [refresh, setRefresh] = useState<number>(1);
  const NftData = useNftData(NFTId, refresh)
  const history = useHistory()
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <Container>
      <div className='searchDetail'>
        <div className='left-content'>
          <div className='img'>
          {NftData?.SaoStorage ? <img src={NftData?.SaoStorage} /> : <EmptyImg/>}
          </div>
          <div className='properties'>
            <div><span>Properties</span> <RefreshIcon onClick={() => {setRefresh(refresh + 1)}}/></div>
            <div>
              {NftData?.Properties && NftData?.Properties.map((v:any) => 
                  <div>
                   <div>{v.Name}</div>
                   <div>
                     <div>{v.Name.includes('Date') ? moment(v.Value).format('YYYY-MM-DD HH:mm:ss'): v.Value}</div>
                     <div>{v.Proportion && `${numeral(v.Proportion * 100).format('0,0.00')}%`}</div>
                   </div>
                 </div>
              )}
            </div>
          </div>
          <div className='collection-info'>
            <div>Collection</div>
            <div onClick={() => {history.push(`/collection/${NftData?.Collection?.Contract}`)}}>
              <div className='avatar'>
                {NftData?.Collection?.Cover ? <img src={NftData?.Collection?.Cover} alt="" /> : <EmptyImg/>}
              </div>
              <div>
                <div>{NftData?.Collection?.Name}</div>
                <div>
                {NftData?.Collection?.Description}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='right-content'>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            autohide={false}
            target="Name"
            toggle={toggle}
          >
           {NftData?.Name}
          </Tooltip>
          <div>
            <div>
              <div className='name' id="Name">{NftData?.Name}</div>
              <div className='id'>#{NftData?.TokenId}</div>
            </div>
            <div>
              <div>Current price</div>
              <div>{NftData?.Price && `${numeral(formatUnits(NftData?.Price, 18)).format('0,0.00')} ETH` || `- ETH`}</div>
            </div>
          </div>
          <div>
            <div>
              <div>Contract Address</div>
              <div className='address' onClick={() => {history.push(`/collection/${NftData?.Collection?.Contract}`)}}>{NftData?.Collection?.Contract && shortenAddress(NftData?.Collection?.Contract)}</div>
            </div>
            <div>
              <div>Blockchain</div>
              <div>Ethereum</div>
            </div>
            <div>
              <div>Token Standard</div>
              <div>ERC 721</div>
            </div>
            <div>
              <div>Token ID</div>
              <div>{NftData?.TokenId}</div>
            </div>
            <div>
              <div>Created</div>
              <div>{NftData?.MiningDate && moment(NftData?.MiningDate).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
            <div>
              <div>Owner</div>
              <div className='address' onClick={() => {history.push(`/profile/${NftData?.Address}`)}}>{NftData?.Address && shortenAddress(NftData?.Address)}</div>
            </div>
          </div>
          <div>
            <div className='storage'>
              <div>Storage Address</div>
              <div>
                <InputGroup>
                  <InputGroupText>
                    General Storage
                  </InputGroupText>
                  <Input value={NftData?.Url}/>
                </InputGroup>
                {/* <InputGroup>
                  <InputGroupText>
                    IPFS Storage
                  </InputGroupText>
                  <Input />
                </InputGroup> */}
              </div>
              <div className='saoStorage'>
                <InputGroup>
                  <InputGroupText>
                    SAO Storage
                  </InputGroupText>
                  <Input value={NftData?.SaoStorage}/>
                </InputGroup>
                {/* <div className='storageInfo'>
                  <div>
                    <div>OrderId</div>
                    <div>{NftData?.Id}</div>
                  </div>
                  <div>
                    <div>Notary</div>
                    <div>{`sdda`}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SearchDetailPage
