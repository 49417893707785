import { useState, useCallback } from "react";
import { Container } from 'reactstrap';
import { RouteComponentProps, useLocation, Link } from 'react-router-dom'
import { useHistory } from "react-router";
import InfiniteScroll from 'react-infinite-scroller';
import { useCollectionSearch } from '../hooks'
import { ReactComponent as EmptyImg } from 'assets/img/emptyImg.svg'
import './index.less';

interface searchData {
  imgUrl: string;
  productName: string;
  productNum: string;
  projectName: string;
  projectUrl: string;
  platformName: string;
  platformUrl: string
  chain: string
}

const CollectionList = (props: RouteComponentProps<{ address?: string }>) => {
  const {
    match: {
      params: { address }
    }
  } = props
  const history = useHistory();
  const [pageNo, setPageNo] = useState<number>(0);
  const pageSize = 12;
  const {list, total, fetching} = useCollectionSearch("ethereum", '', pageNo, pageSize)

  const fetchItems = useCallback(() => {
    if(pageNo < total/pageSize - 1){
        if (fetching) {
          return;
        }
        setPageNo(pageNo + 1)
    }
  }, [pageNo, total, fetching])

  const loader = (
    <div key="loader" className="loader">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  );

  return (
    <Container className="collectionList">
      <div className="content">
        <div className="title">Collection</div>
        <div>
          <InfiniteScroll
            loadMore={fetchItems}
            hasMore={list.length < total}
            loader={loader}
          >
            <div className="list">
              {list && list.map((data: any) => {
                return (
                  <div className="item" onClick={() => {history.push(`/collection/${data?.Contract}`)}}>
                    <div className="img">
                      {data?.Cover ? <img src={data?.Cover} alt="" /> : <div className="empty"><EmptyImg/></div>}
                    </div>
                    <div className="info">
                      {/* <div className="avatar">
                        <EmptyImg/>
                      </div> */}
                      <div>
                        <div>{data?.Name}</div>
                        <div>{data?.TotalItem} items</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </Container>
  )
}

export default CollectionList
