import { useState, useEffect } from "react";
import styled from "styled-components";
import { RouteComponentProps } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useHistory } from "react-router";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from 'react-select'
import { ModelsCollection, ModelsNftItem } from '../../interface'
import { useProfileNftList } from '../hooks'
import useActiveWeb3React from "hooks/useActiveWeb3React";
import useENSName from 'hooks/useENSName'
import { shortenAddress } from 'utils/index'
import { ReactComponent as WalletIcon} from 'assets/img/wallet.svg'
import { ReactComponent as CopyIcon } from 'assets/img/copyIcon.svg'
import { ReactComponent as ETHIcon } from 'assets/img/eth.svg'
import './index.less'

const SearchList = (props: RouteComponentProps<{ keywords: string }>) => {
  const {
    match: {
      params: { keywords }
    }
  } = props
  const history = useHistory();
  const { account } = useActiveWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const nftList = useProfileNftList(account);
  const getShortAddress = (address: string | undefined) => {
    return `${address?.slice(0, 5)} ... ${address?.slice(address.length - 4, address.length)}`
  }

  const options = [
    { value: 'all', label: 'All Collections' },
  ]
  return (
    <div className="profile">
      {account && 
        <div className="accountInfo">
          <div>
            <div className="avator"></div>
            <div>
              <div>{ENSName} 
               <CopyToClipboard text={account || ''} onCopy={() => {
                    console.log('Copied!')
                  }}>
                <div className="address">
                  <WalletIcon/>
                  <div>{shortenAddress(account)}</div>
                  <CopyIcon/>
                </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div>
            <Button color="primary">Follow</Button>
          </div>
          <div className="edit"></div>
        </div>
      }
      <div className="content">
        <Select className="select" defaultValue={options[0]} options={options}/>
          <div className="list">
              {nftList.map((data: any) => {
                return (
                  <div className="item" onClick={() => {history.push(`/collection/${data?.Contract}`)}}>
                    <div className="img">
                      <img src={data?.Metadata?.image_path} alt="" />
                      <div className="tag"><ETHIcon/></div>
                    </div>
                    <div>
                      <p>{data?.Collection.Name} #{data?.TokenId}</p>
                      <div className="price">
                        <div>#{data?.TokenId}</div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
      </div>
    </div>
  )
}

export default SearchList 
