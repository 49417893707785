import { useCallback, useEffect, useState } from 'react';
import Web3 from "web3";
import { post, get, request } from 'utils/request';
import { API_URL, walletconnect } from 'constants/index';
import { getSign, clearLocalStorage } from 'utils/txSign';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import throttle from 'lodash.throttle';
import {ModelsNFTChangeHistory, ModelsCollection } from "../interface";

export function useCollectionEvents(collectionId: any) : any {
    const [list, setList] = useState<any>({data: [], total: 0});
    const fetchData = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let pageNo: string | null = urlParams.get("page")
        if (!pageNo) {
            pageNo = '0'
        }
        let data : any = {
            key: collectionId + '',
            pageSize: 10,
            pageNo: parseInt(pageNo)
        }
        post(`${API_URL}/collection/transfer/list`, data).then((res: any) => {
            if(res && res.data){
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[collectionId])
    return list;
}


export function useNftItemEvents(nftItemId: any) : any {
    const [list, setList] = useState<any>({data: [], total: 0});
    const fetchData = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let pageNo: string | null = urlParams.get("page")
        if (!pageNo) {
            pageNo = '0'
        }
        let data : any = {
            key: nftItemId + '',
            pageSize: 10,
            pageNo: parseInt(pageNo)
        }
        post(`${API_URL}/nft/transfer/list`, data).then((res: any) => {
            if(res && res.data){
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[nftItemId])
    return list;
}

export function useCollectionInfo(network: string, contract: string) : any {
    const [collection, setCollection] = useState<any>({})
    const fetchData = () => {
        let params: any = {
            network: network,
        };
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        get(`${API_URL}/collection/${contract}?${query}`).then((res: any) => {
            if(res && res.data){
                setCollection(res.data)
            }
        })
    }
    useEffect(() => {
        if(contract){
            fetchData()
        }
    }, [contract])
    return collection
}

export function useCollectionItems(collectionId: any, pageNo: number, pageSize: number, tokenId?:string ) : any {
    const [list, setList] = useState<any>([])
    const [total, setTotal] = useState<number>(1)
    const [fetching, setFetching] = useState<boolean>(false);
    const fetchData = useCallback(() => {
        let data : any = {
            key: collectionId,
            pageSize,
            pageNo,
            tokenId,
        }
        setFetching(true)
        post(`${API_URL}/collection/nft/list`, data).then((res: any) => {
            if(!res.data.data){
                return
            }
            if (pageNo === 0) {
                setList(res.data.data)
            } else {
                setList((oldList: any[]) => [...oldList, ...res.data.data])
            }
            setTotal(res.data.total)
            setFetching(false)
        })
    },[collectionId, tokenId, pageNo, pageSize, fetching])
    useEffect(() => {
        if(collectionId){
            fetchData()
        }
    },[collectionId, tokenId, pageNo])
    return {list, total, fetching};
}

export function useCollectionSearch(network:  string, keyword: string, pageNo: number, pageSize: number): any {
    const [list, setList] = useState<any>([])
    const [total, setTotal] = useState<number>(1)
    const [fetching, setFetching] = useState<boolean>(false);
    const fetchData = useCallback(() => {
        let data : any = {
            key: keyword,
            network: network,
            pageSize,
            pageNo,
        }
        setFetching(true)
        post(`${API_URL}/collection/search`, data).then((res: any) => {
            if(!res.data.data){
                return
            }
            if (pageNo === 0) {
                setList(res.data.data)
            } else {
                setList((oldList: any[]) => [...oldList, ...res.data.data])
            }
            setTotal(res.data.total)
            setFetching(false)
        })
    }, [pageNo, pageSize])
    useEffect(() => {
        fetchData()
    },[keyword, network, pageNo, pageSize])
    return {list, total, fetching};
}

export function useSearch(keyword: string): any {
    const [list, setList] = useState<any>({});
    const fetchData = () => {
        get(`${API_URL}/search/${keyword}`).then((res: any) => {
            if(res && res.data){
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        if(keyword){
            fetchData()
        }else{
            setList({})
        }
    },[keyword])
    return list;
}

export function useBlockList(productId: string, pagination: any): any{
    const [data, setData] = useState<any>({});
    const fetchData = () => {
        let params: any = {
            productId,
            page: pagination.current,
            size: pagination.pageSize,
        };
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        get(`${API_URL}/sao/api/product/getBlockList?${query}`).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[productId, pagination])
    return data;
}

export function useNftChangeHistoryList(id: string): any {
    const [data, setList] = useState<any>({});
    const fetchData = () => {
        get(`${API_URL}/nft/changeHistoryList/${id}`).then((res: any) => {
            if(res && res.data){
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        if(id){
            fetchData()
        }
    },[id])
    return data;
}

export function useProfileNftList(address: any, fromCollection?: string): any{
    const [data, setSearchData] = useState<any>([]);
    const {account, library } = useActiveWeb3React();
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false);

        let params: any = {
            fromCollection: fromCollection,
        };
        if(!fromCollection || fromCollection === 'all') delete params.fromCollection
        let query = Object.keys(params)
            .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');

        get(`${API_URL}/profile/nft/${address}?${query}`, sign).then((res: any) => {
            if(res && res.data){
                setSearchData(res.data)
            }
        })
    },[address, fromCollection])
    useEffect(() => {
        if(address){
            setSearchData([])
            fetchData()
        }
    },[address, fromCollection])
    return data;
}

export function useNftProfile(): any {
    const [data, setData] = useState<any>({});
    const { account, library } = useActiveWeb3React()
    const fetchData = async() =>  {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/nft/profile`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[])
    return data;
}

export function useProfile(address: any, refresh?: number): any {
    const [data, setData] = useState<any>({});
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async() =>  {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/profile/${address}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }, [address])
    useEffect(() => {
        if(address){
            fetchData()
        }
    },[refresh, address])
    return data;
}

export const postProfile  = async(library: any, account: any, data: any)  => {
    const sign = await getSign(library, account, false)
    if(!sign){
        return null
    }
    try {
        const url = `${API_URL}/profile` 
        const res = await request(url, {
            headers: {
                ...sign,
            },
            body: JSON.stringify(data),
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}

export function useNftData(id: string, refresh?: number): any {
    const [data, setData] = useState<any>({});
    const fetchData = async() =>  {
        get(`${API_URL}/nft/${id}`).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        if(id){
            fetchData()
        }
    },[id, refresh])
    return data;
}

export const postFollow  = async(library: any, account: any, data: any)  => {
    const sign = await getSign(library, account, false)
    if(!sign){
        return null
    }
    try {
        const url = `${API_URL}/follow?following=${data.following}&status=${data.status}` 
        const res = await request(url, {
            headers: {
                ...sign,
            },
            // body: JSON.stringify(data),
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}