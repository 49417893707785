import React, {StrictMode, Suspense, useEffect } from 'react'
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import store from 'state'
import ThemeProvider from 'theme'
//@ts-ignore
import {NotificationContainer} from 'react-notifications';
import Header from "./components/Header";
import SearchList from "./views/SearchList"
import SearchDetail from './views/SearchDetail'
import CollectionList from "./views/CollectionList";
import CollectionDetail from "./views/CollectionDetail";
import { NetworkContextName } from './constants'
import getLibrary from './utils/getLibrary'
import Web3ReactManager from 'components/Web3ReactManager'
import Profile from "./views/Profile";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './App.less';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function App() {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Suspense fallback={null}>
            <Provider store={store}>
              <ThemeProvider>
                <HashRouter>
                  <Header />
                    <Web3ReactManager>
                      <Switch>
                        <Route component={Profile} exact path="/profile" />
                        <Route component={CollectionList} exact path="/" />
                        <Route component={CollectionList} exact path="/collections" />
                        <Route component={CollectionDetail} exact path="/collection/:Contract" />
                        <Route component={SearchList} exact path="/search/:keywords" />
                        <Route component={SearchDetail} exact path="/detail/:NFTId" />
                        <Route component={Profile} exact path="/profile" />
                        <Route component={Profile} exact path="/profile/:address" />
                      </Switch>
                    </Web3ReactManager>
                    <NotificationContainer/>
                </HashRouter>
              </ThemeProvider>
            </Provider>
          </Suspense>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </StrictMode>
  );
}

export default App;
