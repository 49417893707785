import { useCallback, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useLocation, Link } from "react-router-dom";
import { Collapse,InputGroup, Input, Button, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { Search, Menu } from 'react-feather'
import Web3Status from '../Web3Status'
import { networkListener, setupEthMainnet } from 'utils/bscUtil'
import { useSearch } from 'views/hooks';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useToggle } from 'hooks/useToggle';
import LogoWhite from "assets/img/LogoWhite.svg";
import { ReactComponent as EmptyImg } from 'assets/img/emptyImg.svg'
import { ReactComponent as EnterIcon } from 'assets/img/enter.svg'
import './index.less'

const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  let location = useLocation();
  const [searchText , setSearchText] = useState<string>('')
  const { chainId } = useActiveWeb3React()
  const searchData = useSearch(searchText)
  const [showDropdown, toggleDropdown] = useToggle(false)
  const [collapsed, setCollapsed] = useState(false);
  const ref = useRef()
  const headerRef = useRef()

  useOnClickOutside(ref, showDropdown ? () => {setSearchText(''); toggleDropdown()} : undefined)
  useOnClickOutside(headerRef, collapsed ? () => {setCollapsed(!collapsed)} : undefined)

  const history = useHistory();

  const scrollHeader = () => {
    if (!document.querySelector('.header')) {
        return;
    }

    var scrollTop = document.documentElement.scrollTop;
    if (scrollTop >= 50) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  }


  useEffect(() => {
    networkListener();
    window.addEventListener('scroll', scrollHeader, false);

    return () => {
      window.removeEventListener('scroll', scrollHeader, false);
    }
  }, [])

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
      if(chainId){
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
              if(chainId !== 1){
                setupEthMainnet(chainId)
              }
            }
        });
        if (document.visibilityState === 'visible') {
          if(chainId !== 1){
            setupEthMainnet(chainId)
          }
        }
      }
    if(location.pathname && isNavVisible){
      setNavVisibility(!isNavVisible);
    }
  }, [location.pathname])

  useEffect(() => {
    if((searchData.CollectionCount > 0 || searchData.AccountCount > 0) && !showDropdown ){
      toggleDropdown();
    }
    if(!searchData.CollectionCount && !searchData.AccountCount && showDropdown){
      toggleDropdown()
    }
  },[searchData, showDropdown])

  const handleMediaQueryChange = (mediaQuery: any) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const isShowBlackBackground = () => {
    if (location.pathname === "/") return false
    return true
  }

  const onSearch = useCallback(() => {
      if(searchText !== ''){
          // history.push(`/search/${searchText}`)
      }
  }, [searchText]);

  const handleKeyDown = useCallback((e: any) => {
    if (e.key === 'Enter' && searchText !== '') {
      setSearchText('');
      // if(!showDropdown){
      // }
      if(searchData.CollectionCount > 0){
        history.push(`/collection/${searchData.Collections[0].Contract}`)
        toggleDropdown();
        return;
      }
      if(searchData.AccountCount > 0){
        history.push(`/profile/${searchData.Accounts[0].Address}`)
        toggleDropdown();
        return;
      }
    }
  }, [searchText, searchData])

  const handleCollectionClick = (collection: any) => {
    setSearchText('');
    if(showDropdown){
      toggleDropdown();
    }
    history.push(`/collection/${collection.Contract}`)
  }
  const handleAccountClick = (account: any) => {
    setSearchText('');
    if(showDropdown){
      toggleDropdown();
    }
    history.push(`/profile/${account.Address}`)
  }

  const getHighlightedText = (text: string, highlight: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part: any, i: number) => 
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', color: '#5EC800' } : {} }>
            { part }
        </span>)
    } </span>;
  }

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div ref={headerRef as any}>
      <Navbar
        fixed="top"
        expand="sm"
        container
        className={`header ${isScroll ? 'scroll' : ''}`}
      >
        <Button className="Burger" onClick={toggleNavbar}><Menu/></Button>
        <NavbarBrand href="/">
          <img
            src={LogoWhite}
            className="Logo"
            alt="logo"
            onClick={() => history.push("/")}
          />
        </NavbarBrand>
        {isSmallScreen && <button className="wallet-icon">
          <Web3Status className="black"/>
        </button>}
        <Collapse isOpen={collapsed} navbar>
          <Nav navbar>
            <NavItem>
              {/* <Search
                placeholder="Search NFT"
                allowClear
                className="searchBox"
                onFocus={() => (setSearchFocus(true))}
                onBlur={() => (setSearchFocus(false))}
                onSearch={onSearch}
              /> */}
              <InputGroup className="search-group">
                <Button onClick={() => {onSearch()}}>
                  <Search color='#5EC800'/>
                </Button>
                <Input onKeyDown={handleKeyDown} value={searchText} onChange={(v: any) => {setSearchText(v.target.value)}} placeholder="Search by collections or accounts" />
                {showDropdown && <Button>check all results <EnterIcon/></Button>}
                {showDropdown && <div className="dropdown" ref={ref as any}>
                  <div className="head">
                    <div>Collection</div>
                    <div>{searchData.CollectionCount} results</div>
                  </div>
                  <div className="collections">
                    {searchData.Collections && searchData.Collections.map((collection: any) => 
                      <div onClick={() => {handleCollectionClick(collection)}}>
                        <div>
                          {collection.Cover ? <img src={collection.Cover} alt="" /> : <div className="empty"><EmptyImg/></div>}
                          {getHighlightedText(collection.Name, searchText)}
                        </div>
                        <div>{collection.ItemCount}</div>
                      </div>
                    )} 
                  </div>
                  <div className="head">
                    <div>Account</div>
                    <div>{searchData.AccountCount} results</div>
                  </div>
                  <div className="accounts">
                    {searchData.Accounts && searchData.Accounts.map((account: any) => 
                      <div onClick={() => {handleAccountClick(account)}}>
                        {account.Avatar ? <img src={account.Avatar} alt="" /> : <div className="empty"><EmptyImg/></div>}
                        {getHighlightedText(account.Username, searchText)}
                      </div>
                    )}
                  </div>
                </div>}
              </InputGroup>
            </NavItem>    
          </Nav>
          <div className="nav-right">
            <Link to="/collections">
              Explore
            </Link>
            {!isSmallScreen && <Web3Status />}
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};
export default Header;
