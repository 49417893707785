import React, { useState, useCallback } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, InputGroupText, InputGroup} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Plus } from 'react-feather'
//@ts-ignore
import { NotificationManager } from 'react-notifications';
import { postProfile } from 'views/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ReactComponent as CopyIcon } from 'assets/img/copyIcon.svg'
import { ReactComponent as RingIcon } from 'assets/img/ring.svg'
import './index.less'

interface EditProfileModalProps {
  isOpen: boolean
  profile: any
  toggle: () => void
  refreshProfile: () => void
}

export default function EditProfileModal({
    isOpen,
    toggle,
    refreshProfile,
    profile,
}: EditProfileModalProps) {
    const { library, account } = useActiveWeb3React()
    const [file, setFile] = useState<any>('')
    const [refresh, setRefresh] = useState<boolean>(false)

    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
          NotificationManager.error('You can only upload JPG/PNG file!');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          NotificationManager.error('Image must smaller than 10MB!');
        }
        return isJpgOrPng && isLt10M;
    }

    const handleUploadImage = (event: any) =>{
        const file = event.target.files[0]
        if(!beforeUpload(file)){
            return
        }
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            setFile(fileReader.result)
        }
    }

    const handleSubmit = useCallback(async(e: any) => {
        e.preventDefault();
        const data = {
            avatar: file,
            biography: e.target.bio.value,
            username: e.target.name.value
        }
        if(!file){ delete data.avatar}
        setRefresh(true)
        const res = await postProfile(library, account, data)
        if(res && res.code === 200){
            refreshProfile();
            NotificationManager.success('Save Success')
        }else{
            NotificationManager.error('Save Failed')
        }
        setRefresh(false)
    }, [file, refresh])

    return (
        <Modal className='editProfileModal' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Profile</ModalHeader>
            <ModalBody>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="file">
                        <Input
                            id="file"
                            name="file"
                            type="file"
                            onChange={handleUploadImage}
                            accept=".jpeg,.png,.jpg"
                        />
                        <div className='uploadBtn'>{file || profile?.Avatar ? <img src={file || profile?.Avatar}/> : <Plus color="#5EC800" size={36}/>}</div>
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label for="name">
                        User name
                    </Label>
                    <Input
                        id="name"
                        name="name"
                        defaultValue={profile?.Username}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="bio">
                        Bio
                    </Label>
                    <Input
                        id="bio"
                        name="bio"
                        defaultValue={profile?.Biography}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="address">
                        Wallet address
                    </Label>
                    <InputGroup>
                        <Input
                            id="address"
                            name="address"
                            defaultValue={profile?.Address}
                            disabled
                        />
                        <InputGroupText>
                            <CopyToClipboard text={''} onCopy={() => {
                                NotificationManager.success('Copied');
                            }}>
                            <CopyIcon/>
                            </CopyToClipboard>
                        </InputGroupText>
                    </InputGroup>
                </FormGroup>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                    <Button type="submit" color="primary" disabled={refresh} onClick={toggle}>
                        Save {refresh && <RingIcon/>}
                    </Button>
                </ModalFooter>
            </Form>
            </ModalBody>
        </Modal>
    )
}
