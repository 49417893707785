import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { NetworkConnector } from './NetworkConnector';

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL || ''
const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL
export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')
export const NetworkContextName = 'NETWORK'


export const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 56, 97]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
    rpc: { 1: NETWORK_URL },
    bridge: WALLETCONNECT_BRIDGE_URL,
    qrcode: true,
    pollingInterval: 15000
})

const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

// mainnet only
export const portis = new PortisConnector({
    dAppId: PORTIS_ID ?? '',
    networks: [1]
})

export interface WalletInfo {
    connector?: AbstractConnector
    name: string
    iconName: string
    description: string
    href: string | null
    color: string
    primary?: true
    mobile?: true
    mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    // INJECTED: {
    //   connector: injected,
    //   name: 'Injected',
    //   iconName: 'arrow-right.svg',
    //   description: 'Injected web3 provider.',
    //   href: null,
    //   color: '#010101',
    //   primary: true
    // },
    METAMASK: {
        connector: injected,
        name: 'MetaMask',
        iconName: 'metamask-fox.svg',
        description: 'Easy-to-use browser extension.',
        href: null,
        color: '#E8831D'
    },
    WALLET_CONNECT: {
        connector: walletconnect,
        name: 'WalletConnect',
        iconName: 'walletConnectIcon.svg',
        description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
        href: null,
        color: '#4196FC',
        mobile: true
    },
    // WALLET_LINK: {
    //   connector: walletlink,
    //   name: 'Coinbase Wallet',
    //   iconName: 'coinbaseWalletIcon.svg',
    //   description: 'Use Coinbase Wallet app on mobile device',
    //   href: null,
    //   color: '#315CF5'
    // },
    // COINBASE_LINK: {
    //   name: 'Open in Coinbase Wallet',
    //   iconName: 'coinbaseWalletIcon.svg',
    //   description: 'Open in Coinbase Wallet app.',
    //   href: 'https://go.cb-w.com/mtUDhEZPy1',
    //   color: '#315CF5',
    //   mobile: true,
    //   mobileOnly: true
    // },
    // FORTMATIC: {
    //   connector: fortmatic,
    //   name: 'Fortmatic',
    //   iconName: 'fortmaticIcon.png',
    //   description: 'Login using Fortmatic hosted wallet',
    //   href: null,
    //   color: '#6748FF',
    //   mobile: true
    // },
    // Portis: {
    //   connector: portis,
    //   name: 'Portis',
    //   iconName: 'portisIcon.png',
    //   description: 'Login using Portis hosted wallet',
    //   href: null,
    //   color: '#4A6C9B',
    //   mobile: true
    // }
}

export const network = new NetworkConnector({
    urls: {
        [NETWORK_CHAIN_ID]: NETWORK_URL,
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        56: 'https://bsc-dataseed.binance.org',
    },
    defaultChainId: NETWORK_CHAIN_ID
})

export const API_URL = process.env.REACT_APP_API_URL

export const ChainId = {
    MAINNET: 1,
    RINKEBY: 4,
}