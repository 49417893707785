import { useCallback, useState } from 'react'
import { RouteComponentProps, useLocation, Link } from 'react-router-dom'
import { Container } from 'reactstrap';
import { useHistory } from "react-router";
import { formatUnits } from '@ethersproject/units'
import moment from 'moment'
import numeral from 'numeral'
import { InputGroup, Input, Button } from 'reactstrap';
import { Search } from 'react-feather'
import InfiniteScroll from 'react-infinite-scroller';
import { shortenAddress } from 'utils/index'
import {useCollectionItems, useCollectionInfo, useCollectionEvents} from '../hooks'
import { ReactComponent as ETHIcon } from 'assets/img/eth.svg'
import { ReactComponent as EmptyImg } from 'assets/img/emptyImg.svg'
import "./index.less"

const CollectionDetail = (props: RouteComponentProps<{ Contract: string }>) => {
  const {
    match: {
      params: { Contract }
    }
  } = props
  const history = useHistory();
  const [tokenId, setTokenId] = useState<string>('')
  const info = useCollectionInfo("ethereum", Contract)
  const [pageNo, setPageNo] = useState<number>(0);
  const pageSize = 10;
  const {list, total, fetching} = useCollectionItems(info?.Id, pageNo, pageSize, tokenId)

  const fetchItems = useCallback(() => {
    if(pageNo < total/pageSize - 1){
        if (fetching) {
          return;
        }
        setPageNo(pageNo + 1)
    }
  }, [pageNo, total, fetching])

  const handleTokenIDChange = (e: any) =>{
    setTokenId(e.target.value)
    setPageNo(0)
  }

  const loader = (
    <div key="loader" className="loader">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  );
  return (
    <div className="collection">
      <div className='banner'  
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 76.76%), url(${info?.Banner})`,   
          height: info?.Banner ? '356px' : 'auto'
        }}>
        <div className="info" style={{height: info?.Banner ? '356px' : 'auto'}}>
          <div className='avatar'>
            {info?.Cover ? <img src={info?.Cover} alt="" /> : <div className="empty"><EmptyImg/></div>}
          </div>
          <div>
            <div className='info1'>
              <div className='avatar'>
                {info?.Cover ? <img src={info?.Cover} alt="" /> : <div className="empty"><EmptyImg/></div>}
              </div>
              <div className='name'>{info?.Name}</div>
              {/* <div className='creator'>by <span>{info?.Creator && shortenAddress(info?.Creator)}</span></div> */}
              <div className='time'>Created at <span>{info?.CreatedAt && moment(info?.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</span></div>
            </div>
            <div className='bio'>{info?.Description}</div>
            <div className='info2'>
              <div>{info?.TotalItem}<span>items</span></div>
              <div>{info?.TotalOwner}<span>owners</span></div>
              <div>Ethereum<span>blockchain</span></div>
              <div>{info?.FloorPrice && numeral(formatUnits(info?.FloorPrice, 18)).format('0,0.00')} ETH<span>floor price</span></div>
              <div>{info?.Updates}<span>updates</span></div>
            </div>
          </div>
        </div>
      </div>
      <div className='content'>
        <Container>
          <InputGroup className="search-group">
              <Button>
                <Search color='#5EC800'/>
              </Button>
              <Input placeholder="Token id" onChange={handleTokenIDChange}/>
            </InputGroup>
          <div>
            <InfiniteScroll
              loadMore={fetchItems}
              hasMore={list.length < total}
              loader={loader}
            >
              <div className="list">
                {list.map((item: any) => (
                    <div key={item.Id} className="item" onClick={() => {history.push(`/detail/${item?.Id}`)}}>
                      <div className="img">
                        {item?.Url ? <img src={item?.Url} alt="" /> : <div className="empty"><EmptyImg/></div>}
                        <div className="tag"><ETHIcon/></div>
                      </div>
                      <div>
                        <p>{item?.Collection?.Name} #{item?.TokenId}</p>
                        <div className="price">
                          <div>#{item?.TokenId}</div>
                          <div>{item?.Price && `${numeral(formatUnits(item?.Price, 18)).format('0,0.00')} ETH`}</div>
                        </div>
                      </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </Container>
      </div>
    </div>
  )
}






export default CollectionDetail
