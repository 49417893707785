import { useState, useCallback } from "react";
import styled from "styled-components";
import { RouteComponentProps } from 'react-router-dom'
import { Button } from 'reactstrap'
import moment from 'moment';
import numeral from "numeral";
import { formatUnits } from '@ethersproject/units'
import { useHistory } from "react-router";
import { CopyToClipboard } from 'react-copy-to-clipboard';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
import Select from 'react-select'
import { useProfileNftList, useProfile, postFollow } from '../hooks'
import { useWeb3React } from '@web3-react/core'
import { walletconnect } from '../../constants'
import useENSName from 'hooks/useENSName'
import { shortenAddress } from 'utils/index'
import { clearLocalStorage } from 'utils/txSign'
import EditProfileModal from "components/EditProfileModal";
import { ReactComponent as WalletIcon} from 'assets/img/wallet.svg'
import { ReactComponent as CopyIcon } from 'assets/img/copyIcon.svg'
import { ReactComponent as ETHIcon } from 'assets/img/eth.svg'
import { ReactComponent as EmptyImg } from 'assets/img/emptyImg.svg'
import { ReactComponent as EditIcon } from 'assets/img/edit.svg'
import { ReactComponent as LogoutIcon } from 'assets/img/logout.svg'
import AvatarPNG from "assets/img/avatar.png";
import './index.less'

const Profile = (props: RouteComponentProps<{ address: string }>) => {
  const {
    match: {
      params: { address }
    }
  } = props
  const history = useHistory();
  const [refresh, setRefresh] = useState<number>(1)
  const { account, deactivate, connector, library } = useWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const profile = useProfile(address? address : account ,refresh)
  const nftList = useProfileNftList(address? address : account);
  const [showProfleModal, setShowProfileModal] = useState<boolean>(false);
  const [selectOptionId, setSelectOptionId] = useState<string>('all');

  const logOut = () => {
    deactivate();
    clearLocalStorage()
    if(connector === walletconnect){
      //@ts-ignore
      connector.close();
    }
  }
  let options: any = [
    { value: 'all', label: 'All Collections' },
  ]
  const collectionMap = new Map()
  nftList.forEach((data: any) => {collectionMap.set(data.Collection.Id,data.Collection.Name)});
  for (const [key, value] of collectionMap) {
    console.log(`${key} = ${value}`);
    options = options.concat([{value: key, label: value}])
  }

  const handleSelectChange = (option: any) => {
    console.log('selected:', option)
    setSelectOptionId(option.value)
  }

  const handleFollow = useCallback(async(e: any) => {
    e.preventDefault();
    const data = {
        following: address,
        status: profile.Followed === 0 ? 1 : 0,
    }
    const res = await postFollow(library, account, data)
    if(res && res.code === 200){
      setRefresh(refresh + 1);
      NotificationManager.success(profile.Followed === 0 ? 'Follow Success' : 'Unfollowed');
    }
  }, [profile])


  return (
    <div className="profile">
      <EditProfileModal profile={profile} isOpen={showProfleModal} toggle={() => setShowProfileModal(!showProfleModal)} refreshProfile={() => {setRefresh(refresh + 1)}}/>
      {account && 
        <div className="accountInfo">
          <div>
            <div className="avatar">{profile.Avatar ? <img src={profile.Avatar}/>: <img src={AvatarPNG}/>}</div>
            <div>
              <div className="name">
                {profile.Username && <div className="username">{profile.Username}</div>}
                <CopyToClipboard text={address || account} onCopy={() => {
                      NotificationManager.success('Copied');
                    }}>
                  <div className="address">
                    <WalletIcon/>
                    <div>{(address || account)  && shortenAddress(address || account)}</div>
                    <CopyIcon/>
                  </div>
                </CopyToClipboard>
              </div>
              <div className="info">
                <div>Born in <span>{profile.Created && moment(profile.Created).format("D, MMM YY")}</span></div>
                <div>{numeral(profile.TransferCount).format('0,0')} Transfers</div>
                <div>{numeral(profile.NftCount).format('0,0')} NFTs</div>
              </div>
              <div className="bio">{profile.Biography}</div>
            </div>
          </div>
          {address === account || !address ?
            <div className="edit">
              <Button onClick={() => {setShowProfileModal(true)}}><EditIcon/>Edit</Button>
              <Button onClick={logOut}><LogoutIcon/>Logout</Button>
            </div>
            :
            <div>
              <Button className="follow" color="primary" onClick={handleFollow}>{profile.Followed === 0 ? '+ Follow' : '- Unfollow'}</Button>
            </div>
          }

        </div>
      }
      <div className="content">
        <Select className="select" defaultValue={options[0]} options={options} onChange={handleSelectChange}/>
          <div className="list">
              {nftList.filter((nft: any) => {if(selectOptionId === 'all') {return true} else return nft?.Collection.Id === selectOptionId}).map((data: any) => {
                return (
                  <div className="item" onClick={() => {history.push(`/detail/${data?.Id}`)}}>
                    <div className="img">
                      {data?.Url ? <img src={data?.Url} alt="" /> : <div className="empty"><EmptyImg/></div>}
                      <div className="tag"><ETHIcon/></div>
                    </div>
                    <div>
                      <div>{data?.Collection.Name}</div>
                      <div className="price">
                        <div>#{data?.TokenId}</div>
                        <div>{data?.Price && `${numeral(formatUnits(data?.Price, 18)).format('0,0.00')} ETH`}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
      </div>
    </div>
  )
}

export default Profile 
